





















import { Component, Vue, Prop } from "vue-property-decorator";
import { Camp } from "@/store/payments-module";

import formatDate from "@/common/filters/format-date";

@Component({ components: {}, filters: { formatDate } })
export default class CampDetail extends Vue {
    @Prop({ required: true }) camp!: Camp;
    @Prop({ required: true, type: Boolean }) gdpr!: boolean;
}
