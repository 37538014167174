var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"custom-filter":_vm.fullSearch,"loading":_vm.loading,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"wrap"},[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.showDownload)?_c('v-btn',{attrs:{"color":"primary","href":_vm.download,"target":"_blank","rel":"noreferrer"}},[_vm._v("Download")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.transactionId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap"},[_vm._v(_vm._s(item.transactionId))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap"},[_vm._v(_vm._s(item.email))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap"},[_vm._v(_vm._s(item.name))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" €"+_vm._s(item.items.reduce(function (a, b) { return a + b.total; }, 0).toFixed(2))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":_vm.color(item.orderStatus)}},[_vm._v(_vm._s(_vm.status(item.orderStatus)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',[_c('tbody',[_vm._l((item.items),function(detail,didx){return [_c('tr',{key:didx},[_c('td',[_vm._v(_vm._s(detail.description)+" @ €"+_vm._s(detail.total))]),_c('td',{staticClass:"text-align-end"},[_vm._v(_vm._s(_vm._f("formatDate")(detail.date)))])]),(detail.extra && detail.orderItemType === 4)?_c('tr',{key:(didx + "-e")},[_c('td',[_c('camp-detail',{attrs:{"camp":detail.extra,"gdpr":detail.gdpr}})],1)]):_vm._e()]})],2)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }