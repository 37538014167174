import { axios } from "./axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import store from "../store";

@Module({
    dynamic: true,
    namespaced: true,
    store,
    name: "orders",
})
export class OrdersModule extends VuexModule {
    @Action
    getAll({ type }: { type: "shop" | "fees" | "camp" }) {
        return axios.get<Order[]>(`/orders/${type}`);
    }

    @Action
    getMy() {
        return axios.get<Order[]>("/orders/my");
    }
}

export interface Order {
    id?: number;
    transactionId: string;
    totalPaid: string;
    email: string;
    name: string;
    status: string;
    items: [];
}

export interface OrderItem {
    id?: number;
    date: string;
    details: string;
}
