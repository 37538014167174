





































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { OrdersModule, Order } from "@/store/orders-module";
import { ProfileModule } from "@/store/profile-module";
import formatDate from "@/common/filters/format-date";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import CampDetail from "./CampDetail.vue";

@Component({
    components: { CDeleteConfirm, CampDetail },
    filters: { formatDate },
})
export default class Orders extends Vue {
    @Prop() type!: "my" | "shop" | "fees" | "camp";
    orderstore = getModule(OrdersModule);
    profileModule = getModule(ProfileModule);

    orders: Order[] = [];
    headers = [
        { text: "Transaction Id", value: "transactionId" },
        { text: "Total", value: "total" },
        { text: "Email", value: "email" },
        { text: "Contact", value: "name" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status" },
    ];
    search = "";
    loading = false;
    expanded = [];

    get title() {
        switch (this.type) {
            case "my":
                return "My Orders";
            case "camp":
                return "Camp Fees";
            case "fees":
                return "Membership Fees";
            case "shop":
                return "Shop Orders";
            default:
                return "";
        }
    }

    get showDownload() {
        return this.profileModule.profile.admin && this.type !== "my";
    }

    get download() {
        return this.profileModule.profile.admin ? `/api/orders/${this.type}/csv` : "";
    }

    color(id: number) {
        if (id == 1) {
            return "orange";
        } else if (id == 2) {
            return "green";
        } else if (id == 3) {
            return "green";
        }
        return "";
    }

    status(id: number) {
        if (id == 1) {
            return "Placed";
        } else if (id == 2) {
            return "Paid";
        } else {
            return "Complete";
        }
    }

    mounted() {
        this.get();
    }

    get() {
        this.loading = true;
        if (this.type == "my") {
            return this.orderstore
                .getMy()
                .then((res) => (this.orders = res.data))
                .then(() => (this.loading = false));
        } else {
            return this.orderstore
                .getAll({ type: this.type })
                .then((res) => (this.orders = res.data))
                .then(() => (this.loading = false));
        }
    }

    fullSearch(value: any, search: any, item: any) {
        let lSearch = search?.toLowerCase();
        return (
            value != null &&
            lSearch != null &&
            typeof value === "string" &&
            (value
                .toString()
                .toLowerCase()
                .indexOf(lSearch) !== -1 ||
                item.items?.find((s: { description: string }) => s.description.toLowerCase().indexOf(lSearch) !== -1))
        );
    }
}
